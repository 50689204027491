import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/Group25.svg";
// import { animated, config } from "react-spring";

const LogoWrapper = styled(Logo)`
	.a {
		fill: #fff;
	}
	.b {
		fill: #fc2a00;
		stroke: none;
	}
`;

const NavIcon = () => {
	return <LogoWrapper />;
};

export default NavIcon;
