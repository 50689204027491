import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/distance.svg";
// import { animated, config } from "react-spring";

const LogoWrapper = styled(Logo)`
	.a {
		fill: #f9c4ba;
	}
	.b {
		fill: #fc2a00;
	}
	.c {
		fill: url(#a);
	}
`;

const DistanceIcon = () => {
	return <LogoWrapper />;
};

export default DistanceIcon;
