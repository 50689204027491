import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSpring, animated } from "react-spring";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Transformer from "../../assets/Transformer/Transformer.png";
// import CummingsEngine from "../../assets/CummingsEngine/CummingsEngine.png";
import PerkinsGenerator from "../../assets/PerkinsGenerator/PerkinsGenerator.png";
import dieselEngine from "../../assets/Group8929.png";

import { allProducts } from "../ContactUsPage/data";

import "./ProductDetails.css";

const ProductDetails = () => {
	let { id } = useParams();
	let idParam = id;
	// console.log("hey 4", id);

	let data = allProducts.find((obj) => obj.id === idParam);

	// let data = allProducts.find((item) => item.id === idParam);
	useEffect(
		() => {
			window.scrollTo(0, 0);
			// eslint-disable-next-line
		}, // eslint-disable-next-line
		[]
	);

	const [price, setPrice] = useState(data.cost);

	let priceOptions;
	if (data.spec) {
		priceOptions = data.spec.map((data, index) => {
			return { label: data.specName, value: data.cost, id: index + 1 };
		});
	}

	const handleLocationOptionChange = (e) => {
		// console.log("this was called", e);
		if (e) {
			setPrice(e.value);
		} else {
			setPrice(data.cost);
		}
	};

	const fade = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	});

	// console.log("hey", data);
	return (
		<animated.section style={fade} className="services-section-container">
			<div className="services-section-content-wrapper">
				<article className="services-content-item product-details">
					<div className="product-details-image-wrapper">
						<img src={data.img} alt="" />
					</div>
					<div className="product-details-right-details">
						<div className="product-details-name">{data.name}</div>
						<div className="product-details-price-select-wrapper">
							{data.spec ? (
								<>
									<div className="product-details-price">{price}</div>
									<Select
										className="spec-selector"
										classNamePrefix="react-select"
										isClearable={true}
										placeholder="Select an Option"
										onInputChange={handleLocationOptionChange}
										// value={price}
										onChange={handleLocationOptionChange}
										options={priceOptions}
									/>
								</>
							) : (
								<div className="product-details-price">{data.cost}</div>
							)}
						</div>
						<div className="product-details-summary">{data.summary}</div>
						<Link to="/contact-us" className="product-details-get-quote">
							Get a Quote
						</Link>
					</div>
				</article>
				<article className="services-content-item product-detail-more">
					<div className="top-product-title-link-wrapper">
						<div className="top-product-title product-detail">MORE DETAILS</div>
					</div>
					<div className="des-details">
						<p className="des-details-one">{data.descriptionLine1}</p>
						<p className="des-details-two">{data.descriptionLine2}</p>
					</div>
				</article>
				<section className="top-product-section product-detail">
					<div className="top-product-title-link-wrapper more">
						<div className="top-product-title product-detail">
							MORE PRODUCTS
						</div>
					</div>
					<div className="top-product-items-list">
						<div className="top-product-item-wrapper">
							<Link to="/products/Perkins-Diesel-Engine-Soundproof-Generator">
								<img src={PerkinsGenerator} alt="generator" />
							</Link>
							<Link
								to="/products/Perkins-Diesel-Engine-Soundproof-Generator"
								className="top-product-item-name"
							>
								Perkins Diesel Engine Soundproof Generator
							</Link>
							<div className="top-product-item-price-range">
								₦ 4,000,000 - ₦ 10,800,000
							</div>
							<Link to="/contact-us" className="top-product-item-button">
								Inquire
							</Link>
						</div>
						<div className="top-product-item-wrapper">
							<Link to="/products/Cummins-40kva-1000kva-Diesel-Engine-Generators">
								<img src={dieselEngine} alt="generator" />
							</Link>

							<Link
								to="/products/Cummins-40kva-1000kva-Diesel-Engine-Generators"
								className="top-product-item-name"
							>
								Cummings 40kva-1000kva Diesel Engine Generators
							</Link>
							<div className="top-product-item-price-range">N/A</div>
							<Link to="/contact-us" className="top-product-item-button">
								Inquire
							</Link>
						</div>
						<div className="top-product-item-wrapper">
							<Link to="/products/Beta-Power-Distribution-Transformer">
								<img src={Transformer} alt="generator" />
							</Link>
							<Link
								to="/products/Beta-Power-Distribution-Transformer"
								className="top-product-item-name"
							>
								Beta Power Distribution Transformer 11Kv, 33Kv
							</Link>
							<div className="top-product-item-price-range">
								₦ 1,300,000 - ₦ 5,200,000
							</div>
							<Link to="/contact-us" className="top-product-item-button">
								Inquire
							</Link>
						</div>
					</div>
				</section>
			</div>
		</animated.section>
	);
};

export default ProductDetails;
