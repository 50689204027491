import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import "./ServicesPage.css";

// import MaintenanceSvg from "./MaintenanceSvg.js";
import BackgroundCircle from "./BackgroundCircle.js";

// import InstallationSvg from "./InstallationSvg.js";
// import ConstructionSvg from "./ConstructionSvg.js";
// import ConsultancySvg from "./ConsultancySvg.js";
// import RentalSvg from "./RentalSvg.js";

import MaintenanceImage from "../../assets/MaintenanceIMG.png";
import SalesRentalImage from "../../assets/SalesRentalsIMG.png";
import InstallationImage from "../../assets/InstallationCommissioningIMG.png";
import TrainingAndConsoltancy from "../../assets/TrainingAndConsoltancy.png";

const ServicesPage = () => {
	useEffect(
		() => {
			window.scrollTo(0, 0);
			// eslint-disable-next-line
		}, // eslint-disable-next-line
		[]
	);

	const fade = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	});
	return (
		<animated.section style={fade} className="services-section-container">
			<div className="services-section-content-wrapper">
				<article className="services-content-item">
					<div className="services-content-left-group">
						<div className="services-content-left-header">
							Installation & Commissioning
						</div>
						<div className="services-content-left-text">
							Installation and startup of new power assets can be tedious
							without adequate support. At Jocaro we are with you every step of
							the way to ensure a safe, high grade installation and commisioning
							of your new unit. We provide extensive coverage for all your
							installation needs.
						</div>
						<Link to="/contact-us" className="services-content-left-button">
							Schedule
						</Link>
					</div>
					<div className="services-content-right-group">
						<div className="services-content-right-worker-svg">
							<img src={InstallationImage} alt="installation" />
						</div>
						<div className="services-content-right-background-svg ">
							<BackgroundCircle />
						</div>
					</div>
				</article>
				<article className="services-content-item reverse">
					<div className="services-content-left-group reverse">
						<div className="services-content-left-header">Sales & Rental</div>
						<div className="services-content-left-text reverse">
							At Jocaro we hold an impressive inventory of generators, load
							banks, compressors, lighting towers of various capacities and
							price ranges to meet your power needs. Opportunities, sometimes
							come when we least expect them, you can count on our equipment to
							be in excellent working condition and always delivered on time.
						</div>
						<Link to="/contact-us" className="services-content-left-button">
							Inquire
						</Link>
					</div>
					<div className="services-content-right-group reverse">
						<div className="services-content-right-worker-svg">
							{/* <RentalSvg /> */}
							<img src={SalesRentalImage} alt="installation" />
						</div>
						<div className="services-content-right-background-svg">
							<BackgroundCircle />
						</div>
					</div>
				</article>
				<article className="services-content-item">
					<div className="services-content-left-group">
						<div className="services-content-left-header">Maintenance</div>
						<div className="services-content-left-text">
							At Jocaro we provide an array of engineering and power maintenance
							services for our products, utilizing only highly skilled and
							experienced engineers and technicians specialized in various
							fields of engineering maintenance. Working in close partnership
							with our clients, we design & implement maintenance operations
							ensuring our customer objectives are achieved.
						</div>
						<Link to="/contact-us" className="services-content-left-button">
							Schedule
						</Link>
					</div>
					<div className="services-content-right-group">
						<div className="services-content-right-worker-svg">
							{/* <InstallationSvg /> */}
							<img src={MaintenanceImage} alt="maintenance" />
						</div>
						<div className="services-content-right-background-svg">
							<BackgroundCircle />
						</div>
					</div>
				</article>
				<article className="services-content-item reverse last">
					<div className="services-content-left-group reverse">
						<div className="services-content-left-header">
							Training & Consultancy
						</div>
						<div className="services-content-left-text reverse">
							We understand the importance of practical experience to the youth
							to augment the theoretical knowledge from school. We also provide
							hands-on training to energetic individuals desirous of acquiring
							technical and electro-mechanical skills.
						</div>
						<Link to="/contact-us" className="services-content-left-button">
							Inquire
						</Link>
					</div>
					<div className="services-content-right-group reverse">
						<div className="services-content-right-worker-svg">
							{/* <ConsultancySvg /> */}
							<img src={TrainingAndConsoltancy} alt="training" />
						</div>
						<div className="services-content-right-background-svg">
							<BackgroundCircle />
						</div>
					</div>
				</article>
				{/* <article className="services-content-item">
					<div className="services-content-left-group">
						<div className="services-content-left-header">Commission</div>
						<div className="services-content-left-text">
							We are a strong and stable engineering company with effective
							presence in Nigeria and indeed other African Countries with the
							aim of providing quality engineering products and services to its
							clients.
						</div>
						<div className="services-content-left-button">Schedule</div>
					</div>
					<div className="services-content-right-group">
						<div className="services-content-right-worker-svg">
							<ConstructionSvg />
						</div>
						<div className="services-content-right-background-svg">
							<BackgroundCircle />
						</div>
					</div>
				</article> */}
			</div>
		</animated.section>
	);
};

export default ServicesPage;
