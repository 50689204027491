import React from "react";

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.open ? this.props.open : false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
			this.setState({ open: nextProps.open });
		}
	}

	render() {
		const styles = {
			container: {
				position: "absolute",
				top: this.state.open ? "69px" : "-20px",
				left: 0,
				height: this.state.open ? "14.5rem" : 0,
				width: "100vw",
				display: "flex",
				flexDirection: "column",
				background: "#062a4e 0% 0% no-repeat padding-box",
				opacity: 1,
				transition: "height 0.3s ease",
				zIndex: 2,
				font: "normal normal normal 16px/25px Josefin Sans",
				letterSpacing: "0px",
				color: "#FFFFFF",
				paddingBottom: "2rem",
			},
			menuList: {
				paddingTop: "0.6rem",
			},
		};
		return (
			<div style={styles.container}>
				{this.state.open ? (
					<div style={styles.menuList}>{this.props.children}</div>
				) : null}
			</div>
		);
	}
}

export default Menu;
