import React from "react";

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
		};
	}

	handleHover() {
		this.setState({ hover: !this.state.hover });
	}

	render() {
		const styles = {
			container: {
				opacity: 1,
				animation: "1s appear forwards",
				animationDelay: this.props.delay,
			},
			menuItem: {
				fontFamily: `'Open Sans', sans-serif`,
				fontSize: "1.2rem",
				padding: "1rem 3%",
				margin: "0 7%",
				cursor: "pointer",
				transition: "color 0.2s ease-in-out",
				animation: "0.5s slideIn forwards",
				animationDelay: this.props.delay,
				font: "normal normal normal 16px/25px Josefin Sans",
				letterSpacing: "0px",
				color: "#FFFFFF",
			},
			line: {
				width: "90%",
				height: "1px",
				background: "gray",
				margin: "0 auto",
				animation: "0.5s shrink forwards",
				animationDelay: this.props.delay,
			},
		};
		return (
			<div style={styles.container}>
				<div
					style={styles.menuItem}
					onMouseEnter={() => {
						this.handleHover();
					}}
					onMouseLeave={() => {
						this.handleHover();
					}}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</div>
				{/* <div style={styles.line} /> */}
			</div>
		);
	}
}

export default MenuItem;
