import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import "./ProductsPage.css";

import {
	generators,
	transformers,
	lightingtowers,
	solar,
	compressors,
	loadBanks,
	allProducts,
} from "../ContactUsPage/data";

import SingleProduct from "./SingleProduct";

// import CummingsEngine from "../../assets/CummingsEngine/CummingsEngine.png";
// import Transformer from "../../assets/Transformer/Transformer.png";
// import dieselEngine from "../../assets/dieselGenerator/dieselGenerator@2x.png";
// import renewable from "../../assets/MaskGroup26/MaskGroup26.png";

// import PerkinsGenerator from "../../assets/PerkinsGenerator/PerkinsGenerator.png";

import "./ProductsPage.css";

const ProductsPage = () => {
	const [currentFilter, setCurrentFilter] = useState("all");
	useEffect(
		() => {
			window.scrollTo(0, 0);
			// eslint-disable-next-line
		}, // eslint-disable-next-line
		[]
	);

	const fade = useSpring({
		from: {
			left: 0,
		},
		to: {
			opacity: 1,
		},
	});

	const usedlist = [].concat(
		generators,
		// transformers,
		lightingtowers,
		solar,
		compressors,
		loadBanks
	);
	let generatorsActive;
	let transformersActive;
	let lightingtowersActive;
	let solarActive;
	let compressorsActive;
	let loadBanksActive;
	let usedGenActive;

	let renderedProductList;
	let productList;

	// let filter = () => {

	// }

	switch (currentFilter) {
		case "generators":
			productList = generators;
			generatorsActive = "active";
			break;
		case "transformers":
			productList = transformers;
			transformersActive = "active";
			break;
		case "lightingtowers":
			productList = lightingtowers;
			lightingtowersActive = "active";
			break;
		case "solar":
			productList = solar;
			solarActive = "active";
			break;
		case "compressors":
			productList = compressors;
			compressorsActive = "active";
			break;
		case "loadBanks":
			productList = loadBanks;
			loadBanksActive = "active";

			break;
		case "usedGen":
			productList = usedlist;
			usedGenActive = "active";
			break;
		default:
			productList = allProducts;
			break;
	}
	renderedProductList = productList.map((product) => (
		// <animated.div
		// 	className="top-product-item-wrapper product"
		// 	style={slide}
		// 	key={product.id}
		// >
		// 	<img src={product.img} alt="generator" />
		// 	<Link to="/product" className="top-product-item-name">
		// 		{product.name}
		// 	</Link>
		// 	<div className="top-product-item-price-range">
		// 		{product.cost || product.spec[0]}
		// 	</div>
		// 	<Link to="/contact-us" className="top-product-item-button">
		// 		Inquire
		// 	</Link>
		// </animated.div>
		<SingleProduct
			product={product}
			key={product.id}
			currentFilter={currentFilter}
		/>
	));
	return (
		<animated.section style={fade} className="products-page-container">
			<div className="products-page-content-wrapper">
				<div className="products-filter-list">
					<div
						className={`products-filter-item ${generatorsActive}`}
						onClick={() => setCurrentFilter("generators")}
					>
						Generators
					</div>
					<div
						className={`products-filter-item ${transformersActive}`}
						onClick={() => setCurrentFilter("transformers")}
					>
						Transformers
					</div>
					<div
						className={`products-filter-item ${loadBanksActive}`}
						onClick={() => setCurrentFilter("loadBanks")}
					>
						Load Banks
					</div>
					<div
						className={`products-filter-item ${compressorsActive}`}
						onClick={() => setCurrentFilter("compressors")}
					>
						Compressors
					</div>
					<div
						className={`products-filter-item ${lightingtowersActive}`}
						onClick={() => setCurrentFilter("lightingtowers")}
					>
						Lighting Towers
					</div>
					<div
						className={`products-filter-item ${solarActive}`}
						onClick={() => setCurrentFilter("solar")}
					>
						Solar
					</div>
					<div
						className={`products-filter-item ${usedGenActive}`}
						onClick={() => setCurrentFilter("usedGen")}
					>
						Fairly Used Equipment
					</div>
				</div>
				<div className="products-page-container products">
					{renderedProductList}
				</div>
			</div>
		</animated.section>
	);
};

export default ProductsPage;
