import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/telephone.svg";
// import { animated, config } from "react-spring";

const LogoWrapper = styled(Logo)`
	.a {
		fill: #caeef4;
	}
	.b {
		fill: #1fc6cd;
	}
`;

const TelephoneIcon = () => {
	return <LogoWrapper />;
};

export default TelephoneIcon;
