import React from "react";
import { useSpring, animated } from "react-spring";

import { Link } from "react-router-dom";

const SingleProduct = (props) => {
	let { product } = props;
	const slide = useSpring({
		from: {
			opacity: 0.5,
		},
		to: {
			opacity: 1,
		},
	});
	return (
		<animated.div
			className="top-product-item-wrapper product page"
			style={slide}
			key={product.id}
		>
			<Link
				to={`/products/${product.id}`}
				className="top-product-item-img product"
			>
				<img src={product.img} alt="generator" />
			</Link>
			{/* <img src={product.img} alt="generator" /> */}
			<Link to={`/products/${product.id}`} className="top-product-item-name">
				{product.name}
			</Link>
			<div className="top-product-item-price-range product">
				{props.currentFilter === "usedGen"
					? "PRICE ON DEMAND"
					: product.cost || product.spec[0]}
			</div>
			<Link to="/contact-us" className="top-product-item-button">
				Inquire
			</Link>
		</animated.div>
	);
};

export default SingleProduct;
