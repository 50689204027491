import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";

import ProjectSlider from "./ProjectSlider";

import videoImage from "../../assets/Screenshott/Screenshott@2x.png";

import workerImage from "../../assets/iStock-6/iStock-63.png";
import workerServicing from "../../assets/WorkerServicing.png";

import backgroundqoute from "../../assets/Path23690.svg";

import CLIENTLOGOS from "../../assets/CLIENTLOGOS.png";
import OEMLOGOS from "../../assets/OEM-LOGOS.png";

// import clientsimage from "../../assets/Group-9272.png";

import "./CompanyPage.css";

const CompanyPage = () => {
	useEffect(
		() => {
			window.scrollTo(0, 0);
			// eslint-disable-next-line
		}, // eslint-disable-next-line
		[]
	);

	const fade = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	});

	const FirstNumberprops = useSpring({ from: { val: 0 }, to: { val: 20 } });
	const SecondNumberprops = useSpring({ from: { val: 0 }, to: { val: 75 } });
	const ThirdNumberprops = useSpring({ from: { val: 10 }, to: { val: 100 } });
	return (
		<animated.section
			style={fade}
			className="services-section-container company"
		>
			<div className="services-section-content-wrapper">
				<article className="company-page-first-section">
					<div className="first-section-left">
						<h1>Exceptionally Reliable!</h1>
						<p>
							Fully indigenous engineering company, committed to providing
							quality, competitive electro – mechanical products and services.
						</p>
					</div>
					<div className="first-section-right">
						<img src={workerImage} alt="construction worker" />
					</div>
				</article>
				<article className="company-page-second-section">
					<div className="second-section-left">
						<div className="second-section-left-text-group">
							<div className="second-section-left-text-number">
								<animated.span>
									{FirstNumberprops.val.interpolate((val) => Math.floor(val))}
								</animated.span>
							</div>
							<div className="second-section-left-text-small">
								+ Years of leadership experience
							</div>
						</div>
						<div className="second-section-left-text-group">
							<div className="second-section-left-text-number">
								<animated.span>
									{SecondNumberprops.val.interpolate((val) => Math.floor(val))}
								</animated.span>
							</div>
							<div className="second-section-left-text-small">
								+ Top enterprise projects
							</div>
						</div>
						<div className="second-section-left-text-group">
							<div className="second-section-left-text-number">
								<animated.span>
									{ThirdNumberprops.val.interpolate((val) => Math.floor(val))}
								</animated.span>
							</div>
							<div className="second-section-left-text-small">
								% Customer satisfaction and guarantee
							</div>
						</div>
					</div>
					<div className="second-section-right">
						<h3>We work with major OEMs</h3>
						<div className="second-section-oem-wrapper">
							<img className="oem-logos" src={OEMLOGOS} alt="oems" />
						</div>
					</div>
				</article>
				<article className="company-page-third-section">
					<div className="company-page-third-section-header-wrapper">
						<h1>Our Story</h1>
						<h3>Established ‘95</h3>
					</div>
					<div className="company-page-third-section-body">
						<div className="company-page-third-section-left">
							<img src={videoImage} alt="video screenshot" />
						</div>
						<div className="company-page-third-section-right">
							<div className="company-third-section-right-header">
								A Message from the CEO
							</div>
							<div className="company-third-section-side-text-wrapper">
								<div className="company-third-section-background">
									<img src={backgroundqoute} alt="qoute" />
								</div>
								<div className="company-third-section-qoute-text">
									We are a strong and stable engineering company with effective
									presence in Nigeria with the aim of providing quality
									engineering products and services to its clients.
								</div>
								<div className="company-third-section-qoute-text name">
									Dom Okafor
								</div>
								<div className="company-third-section-qoute-text italic">
									CEO, Jocaro Nigeria Ltd.
								</div>
							</div>
						</div>
					</div>
					<div className="company-page-fourth-section">
						<div className="company-page-fourth-section-sub left-top">
							<div className="company-page-fourth-section-sub-header">
								Mission
							</div>
							<div className="company-page-fourth-section-sub-text">
								To deliver exceptional service in all our core engineering
								products and allied services to the satisfaction of all
								stakeholders.
							</div>
						</div>
						<div className="company-page-fourth-section-image">
							<img src={workerServicing} alt="construction worker" />
						</div>
						<div className="company-page-fourth-section-sub right-bottom">
							<div className="company-page-fourth-section-sub-header">
								Vision
							</div>
							<div className="company-page-fourth-section-sub-text">
								To be the leading integrated engineering solutions company
								driven by shared commitment to excellence.
							</div>
						</div>
					</div>
				</article>
				<article className="company-page-fifth-section">
					<div className="company-page-third-section-header-wrapper clients">
						<h1>Work History</h1>
					</div>
					<div className="company-page-third-section-body">
						<div className="company-page-third-section-body-left">
							<div className="company-page-third-section-body-left__text">
								Some of our clients
							</div>
							<div className="company-page-third-section-body-left__image">
								{/* <img src={clientsimage} alt="clients" /> */}
								<div className="img">
									<img
										className="client-logos-img"
										src={CLIENTLOGOS}
										alt="client logos"
									/>
								</div>
							</div>
						</div>
						<div className="company-page-third-section-right">
							<div className="company-third-section-right-header">
								Our satisfaction commitment
							</div>
							<div className="company-third-section-side-text-wrapper">
								<div className="company-third-section-background">
									<img src={backgroundqoute} alt="qoute" />
								</div>
								<div className="company-third-section-qoute-text">
									We endeavor by all means at our disposal to deliver to our
									clients; projects, goods, processes and services that meet the
									standards as set by the International Standards Organization
									(ISO) and other regulatory bodies as required.
								</div>
								<div className="company-third-section-qoute-text name">
									Jocaro Team
								</div>
								{/* <div className="company-third-section-qoute-text italic">
									Director of Procurement, Shell Nigeria
								</div> */}
							</div>
						</div>
					</div>
					<div className="company-page-third-section-body projects">
						<div className="company-page-third-section-body-left">
							<div className="company-page-third-section-body-left__text">
								A few past projects
							</div>
						</div>
						<ProjectSlider />
					</div>
				</article>
				<article className="company-page-third-section careers">
					<div className="company-page-third-section-header-wrapper clients">
						<h1>Careers</h1>
					</div>
					<div className="company-page-third-section-body careers">
						<div className="career-card">
							<div className="career-card-header">
								Sales and Business Development Executive
							</div>
							<div className="career-card-body">
								<div className="career-card-body-text">
									Hiring in Port Harcourt, Benin, Abuja and Lagos. Have
									experience pushing organization sales and growth? Join us in
									our efforts by contributing to new customer acquisition
								</div>
								<Link className="career-card-body-button" to="/contact-us">
									Apply Now
								</Link>
							</div>
						</div>
						{/* <div className="career-card">
							<div className="career-card-header">
								Senior Consultant - Renewable Energy (Port Harcourt)
							</div>
							<div className="career-card-body">
								<div className="career-card-body-text">
									from ₦ 350,000 per month + Bonus We are a strong and stable
									engineering company with effective presence in Nigeria and
									indeed other African Countries with the aim of providing
									quality engineering products.
								</div>
								<button className="career-card-body-button">Apply Now</button>
							</div>
						</div>
						<div className="career-card">
							<div className="career-card-header">
								Senior Consultant - Renewable Energy (Port Harcourt)
							</div>
							<div className="career-card-body">
								<div className="career-card-body-text">
									from ₦ 350,000 per month + Bonus We are a strong and stable
									engineering company with effective presence in Nigeria and
									indeed other African Countries with the aim of providing
									quality engineering products.
								</div>
								<button className="career-card-body-button">Apply Now</button>
							</div>
						</div> */}
					</div>
				</article>
			</div>
		</animated.section>
	);
};

export default CompanyPage;
