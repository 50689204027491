import React from "react";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";

import NavIconRight from "../NavBar/NavIconRight";
import NavIcon from "../NavBar/NavIcon";

// import { ReactComponent as FooterImage3 } from "../../assets/Group47.svg";
// import { ReactComponent as FooterImage2 } from "../../assets/Group46.svg";
// import vectorDown from "../../assets/Vector1.png";
// import flagBrit from "../../assets/united-kingdom/united-kingdom.png";
// import FooterImage1 from "../../assets/best-podcasts-2018-1/best-podcasts-2018-1.png";
import "./Footer.css";

const Footer = () => {
	const fade = useSpring({
		config: config.wobbly,
		from: {
			opacity: 0,
			bottom: -20,
		},
		to: {
			opacity: 1,
			bottom: 0,
		},
	});
	return (
		<animated.div style={fade} className="footer-container">
			<div className="footer-wrapper">
				<div className="footer-link-brand-wrapper">
					<Link to="/" className="nav-brand-wrapper footer">
						<NavIcon />
					</Link>
					<div className="footer-link-wrapper">
						<Link to="./products" className="footer-link-list">
							<div className="footer-link-list-title">Products</div>
							<div className="footer-link-list-item">Generators</div>
							<div className="footer-link-list-item">Pressure Washers</div>
							<div className="footer-link-list-item">Work Platforms</div>
							<div className="footer-link-list-item">Pumps</div>
							<div className="footer-link-list-item">Lighting Towers</div>
						</Link>
						<Link to="./services" className="footer-link-list">
							<div className="footer-link-list-title">Services</div>
							<div className="footer-link-list-item">Maintenance</div>
							<div className="footer-link-list-item">Rental</div>
							<div className="footer-link-list-item">Installation</div>
							<div className="footer-link-list-item">Consultancy</div>
						</Link>
						<Link to="./company" className="footer-link-list">
							<div className="footer-link-list-title">Company</div>
							<div className="footer-link-list-item">About</div>
							<div className="footer-link-list-item">Affiliates</div>
							<div className="footer-link-list-item">Clients</div>
							<div className="footer-link-list-item">Projects</div>
							<div className="footer-link-list-item">Careers</div>
						</Link>
						<Link to="./contact-us" className="footer-link-list">
							<div className="footer-link-list-title">Contact Us</div>
							<div className="footer-link-list-item">Locations</div>
							<div className="footer-link-list-item">
								Schedule an Appointment
							</div>
						</Link>
					</div>
				</div>
				<Link to="/" className="nav-logo-wrapper">
					<NavIconRight />
				</Link>

				<div className="moblie-footer-icons">
					<Link to="/" className="nav-brand-wrapper footer mobile">
						<NavIcon />
					</Link>
					<Link to="/" className="nav-logo-wrapper footer">
						<NavIconRight />
					</Link>
				</div>
			</div>
		</animated.div>
	);
};

export default Footer;
