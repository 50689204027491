import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/Rectangle44.svg";
// import { animated, config } from "react-spring";

const LogoWrapper = styled(Logo)`
	.a {
		fill: #f8e8e5;
	}
`;

const BackgroundCircle = () => {
	return <LogoWrapper />;
};

export default BackgroundCircle;
