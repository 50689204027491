import React from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";

import dieselEngine from "../../assets/Group8929.png";
import Transformer from "../../assets/Transformer/Transformer.png";
import renewable from "../../assets/MaskGroup26/MaskGroup26.png";

import PerkinsGenerator from "../../assets/PerkinsGenerator/PerkinsGenerator.png";
import jumbotronImage from "../../assets/HeroImage/HeroImage.png";
import genImage from "../../assets/CATGenerator.png";
import blogImage1 from "../../assets/Blog1.svg";
import blogImage2 from "../../assets/Group9224.svg";
import blogImage3 from "../../assets/Group9108.svg";

import exit1 from "../../assets/exit1.svg";
import exit2 from "../../assets/exit2.svg";

import blogexit1 from "../../assets/blogexit1.svg";
import blogexit2 from "../../assets/blogexit2.svg";

import MaintenanceImage from "../../assets/MaintenanceIMG.png";
import SalesRentalImage from "../../assets/SalesRentalsIMG.png";
import InstallationImage from "../../assets/InstallationCommissioningIMG.png";

import "./LandingPage.css";

const LandingPage = () => {
	const fade = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	});
	return (
		<animated.div style={fade} className="page-container">
			<section className="jumbotron-section">
				<div className="jumbotron-section-content">
					<img src={jumbotronImage} alt="a man working on a solar panel"></img>
					<h1 className="jumbotron-section-content-text">
						{" "}
						Your <br />
						<span>Alternative</span> <br /> Power <br />
						Experts
					</h1>
				</div>

				<article className="special-offers-wrapper">
					<div className="special-offers-content">
						<h2>CELEBRATING</h2>
						<div>20 Amazing Years!</div>
						<p>50% OFF MAINTENANCE APPOINTMENTS</p>
						<Link to="/contact-us">Schedule Now</Link>
					</div>
				</article>
			</section>
			<section className="header-sub-section">
				<article className="header-sub-section-content">
					<div className="header-sub-section-large-text">We Are</div>
					<div className="header-sub-section-small-text">
						Industry leaders with 20 years of experience and guaranteed customer
						satisfaction.
					</div>
				</article>
				<div className="header-sub-section-image">
					<img src={genImage} alt="generator" />
				</div>
			</section>
			<section className="top-product-section">
				<div className="top-product-title-link-wrapper landing">
					<div className="top-product-title">Our Top Products</div>
					<Link to="/products" className="top-product-link">
						See all Products
						<div className="exit-group">
							<img className="exit1" src={exit2} alt="h" />
							<img className="exit2" src={exit1} alt="exit" />
						</div>
					</Link>
				</div>
				<div className="top-product-items-list">
					<div className="top-product-item-wrapper ">
						<Link to="/products/Perkins-Diesel-Engine-Soundproof-Generator">
							<img src={PerkinsGenerator} alt="generator" />
						</Link>

						<Link
							to="/products/Perkins-Diesel-Engine-Soundproof-Generator"
							className="top-product-item-name"
						>
							Perkins Diesel Engine Soundproof Generator
						</Link>
						<div className="top-product-item-price-range">
							₦ 4,000,000 - ₦ 10,800,000
						</div>
						<Link to="/contact-us" className="top-product-item-button">
							Inquire
						</Link>
					</div>
					<div className="top-product-item-wrapper landing">
						<Link to="/products/Cummins-40kva-1000kva-Diesel-Engine-Generators">
							<img src={dieselEngine} alt="generator" />
						</Link>

						<Link to="/products" className="top-product-item-name">
							Caterpillar & Perkins Spare Parts
						</Link>
						<div className="top-product-item-price-range">Request Price</div>
						<Link to="/contact-us" className="top-product-item-button">
							Inquire
						</Link>
					</div>
					<div className="top-product-item-wrapper landing">
						<Link to="/products/Beta-Power-Distribution-Transformer">
							<img src={Transformer} alt="generator" />
						</Link>

						<Link
							to="/products/Beta-Power-Distribution-Transformer"
							className="top-product-item-name"
						>
							Beta Power Distribution Transformer 11Kv, 33Kv
						</Link>
						<div className="top-product-item-price-range">
							₦ 1,300,000 - ₦ 5,200,000
						</div>
						<Link to="/contact-us" className="top-product-item-button">
							Inquire
						</Link>
					</div>
				</div>
			</section>
			<section className="top-services-section">
				<div className="top-services-section-wrapper">
					<div className="top-product-title-link-wrapper landing services">
						<div className="top-product-title">Our Top Services</div>
						<Link to="/services" className="top-product-link">
							See all Services
							<div className="exit-group">
								<img className="exit1" src={exit2} alt="h" />
								<img className="exit2" src={exit1} alt="exit" />
							</div>
						</Link>
					</div>
					<div className="top-service-item-list">
						<div className="top-service-item">
							<div className="top-service-item-content">
								<div className="top-service-item-content-img">
									<img src={MaintenanceImage} alt="maintanance" />
								</div>

								<div className="top-service-item-title">Maintenance</div>
								<div className="top-service-item-text">
									We provide maintenance schedules to keep you on track and get
									the best lifespan of all products we offer
								</div>
								<Link to="/services" className="top-service-item-link">
									See Details
								</Link>
							</div>
						</div>
						<div className="top-service-item">
							<div className="top-service-item-content">
								<div className="top-service-item-content-img">
									<img src={InstallationImage} alt="maintanance" />
								</div>
								<div className="top-service-item-title">Installation</div>
								<div className="top-service-item-text">
									We care for our customers, hence we provide end to end
									installation services for quality assurance.
								</div>
								<Link to="/services" className="top-service-item-link">
									See Details
								</Link>
							</div>
						</div>
						<div className="top-service-item">
							<div className="top-service-item-content">
								<div className="top-service-item-content-img">
									<img src={SalesRentalImage} alt="maintanance" />
								</div>
								<div className="top-service-item-title">Sales & Rental</div>
								<div className="top-service-item-text">
									We provide the best quality equipment for short term leasing
									and rentals.
								</div>
								<Link to="/services" className="top-service-item-link">
									See Details
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="renewable-energy-section">
				<div className="renewable-energy-wrapper">
					<div className="renewable-energy-title-wrapper">
						Renewable Energy Solutions
					</div>
					<div className="renewable-energy-content">
						<div className="renewable-energy-section-image">
							<img src={renewable} alt="building surrounded by windmills" />
						</div>
						<div className="renewable-packages-container">
							<div className="renewable-packages-title">
								Affordable, <span>Renewable</span> Packages
							</div>
							<div className="renewable-packages-text">
								We value sustainability! Hence we offer a variety of renewable
								energy packages and services, tailored to your budget. We
								believe renewable energy solutions should be easily accessible.
							</div>
							<div className="renewable-packages">
								<ul>
									<li>Solo 1</li>
									<li>Premium</li>
									<li>Diamond</li>
								</ul>
								<ul>
									<li>Solo 2</li>
									<li>Gold</li>
									<li>Platinum</li>
								</ul>
							</div>
							<Link to="/contact-us" className="top-product-item-button">
								Inquire
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="top-product-section">
				<div className="top-product-title-link-wrapper landing services">
					<div className="top-product-title">Our Recent Blog Posts</div>
					<a
						rel="noreferrer noopener"
						target="_blank"
						href="https://medium.com/jocaro"
						className="top-product-link"
					>
						Visit Our Blog
						<div className="exit-group">
							<img className="exit1" src={blogexit1} alt="h" />
							<img className="exit2 blog" src={blogexit2} alt="exit" />
						</div>
					</a>
				</div>
				<div className="top-product-items-list">
					<a
						rel="noreferrer noopener"
						target="_blank"
						href="https://medium.com/jocaro/the-20-kva-perkins-sound-proof-diesel-generator-engine-what-you-need-to-know-85c8d7e9ebad"
						className="top-product-item-wrapper blog"
					>
						<img src={blogImage1} alt="generator" />
						<div className="top-product-item-name">
							The 20 kVA Perkins Sound-proof Diesel Generator Engine.
						</div>
						<div className="top-product-item-price-range blog">
							{/* We provide maintenance schedules to keep you on track and get the
							best lifespan of */}
						</div>
						<div className="top-product-item-button">Read More</div>
					</a>
					<a
						rel="noreferrer noopener"
						target="_blank"
						href="https://medium.com/jocaro"
						className="top-product-item-wrapper  blog"
					>
						<img src={blogImage2} alt="generator" />
						<div className="top-product-item-name">
							Hybrid Solar Solutions, A Beginner’s Guide. 5 mins read
						</div>
						<div className="top-product-item-price-range blog">
							{/* We provide maintenance schedules to keep you on track and get the
							best lifespan of */}
						</div>
						<div className="top-product-item-button">Read More</div>
					</a>
					<a
						rel="noreferrer noopener"
						target="_blank"
						href="https://medium.com/jocaro"
						className="top-product-item-wrapper  blog"
					>
						<img src={blogImage3} alt="generator" />
						<div className="top-product-item-name">
							How to Pick a Suitable Diesel Engine to Avoid Waste
						</div>
						<div className="top-product-item-price-range blog">
							{/* We provide maintenance schedules to keep you on track and get the
							best lifespan of */}
						</div>
						<div className="top-product-item-button">Read More</div>
					</a>
				</div>
			</section>
			<section className="endorsement-section">
				{/* <div className="endorsement-content-wrapper">
					<div className="endorsement-text">
						“An endorsement is typically a well-known influencer giving their
						public support for a brand. But a testimonial is from a customer or
						client. They may be an unknown person to the reader, but they have
						personal experience with the product or service. Since they’re a
						paying customer, the testimonial is authentic, like a review.”
					</div>
					<div className="endorsement-image-title-wrapper">
						<img src={randomImage} alt="director" />
						<div className="endorsement-name">John Doe </div>
						<div className="endorsement-title">
							Director of Procurement, Shell Nigeria
						</div>
					</div>
				</div> */}
			</section>
		</animated.div>
	);
};

export default LandingPage;
