import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/Group28.svg";
// import { animated, config } from "react-spring";

const LogoWrapper = styled(Logo)`
	// .a,
	// .b {
	// 	fill: #fddb00;
	// }
	// path {
	// 	fill: #000000;
	// }
	.b {
		// font-size: 29px;
		// font-family: Rubik-Bold, Rubik;
		// font-weight: 700;
	}
`;

const NavIconRight = () => {
	return <LogoWrapper />;
};

export default NavIconRight;
