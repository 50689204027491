import React, { useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";

import NavIconRight from "./NavIconRight";
import NavIcon from "./NavIcon";

import MenuItem from "./MenuItem.js";
import MenuButton from "./MenuButton.js";
import Menu from "./Menu.js";

// import burgerIcon from "../../assets/menu-to-arrow-left-3.svg";

import "./NavBar.css";

const NavBar = (props) => {
	// let { pathname } = props.location;
	const history = useHistory();

	const [menuOpen, setMenuOpen] = useState(false);

	let handleLinkClick = (val) => {
		setMenuOpen(false);
		// console.log("here");
		switch (val) {
			case "Products":
				history.push("/products");
				break;
			case "Services":
				history.push("/services");
				break;
			case "Company":
				history.push("/company");
				break;
			case "Contact Us":
				history.push("/contact-us");
				break;

			default:
				history.push("/");
				break;
		}
	};

	const menu = ["Products", "Services", "Company", "Contact Us"];
	const menuItems = menu.map((val, index) => {
		return (
			<MenuItem
				key={index}
				delay={`${index * 0.1}s`}
				onClick={() => {
					handleLinkClick(val);
				}}
			>
				{val}
			</MenuItem>
		);
	});

	// console.log(props.pathname, "location")
	return (
		<nav className="nav-container">
			<div className="nav-wrapper">
				<div className="nav-links-brand-wrapper">
					<div
						className="nav-brand-wrapper"
						onClick={() => {
							handleLinkClick();
						}}
					>
						<NavIcon />
					</div>

					<div className="nav-links-wrapper">
						<NavLink
							exact
							to="/products"
							className="nav-bar-item"
							activeClassName="active"
						>
							Products
						</NavLink>
						<NavLink
							exact
							to="/services"
							className="nav-bar-item"
							activeClassName="active"
						>
							Services
						</NavLink>
						<NavLink
							exact
							to="/company"
							className="nav-bar-item"
							activeClassName="active"
						>
							Company
						</NavLink>
						<NavLink
							exact
							to="/contact-us"
							className="nav-bar-item"
							activeClassName="active"
						>
							Contact Us
						</NavLink>
					</div>
				</div>
				<Link to="/" className="nav-logo-wrapper">
					<NavIconRight />
				</Link>
				{/* <Link to="/" className="nav-logo-wrapper mobile">
					<img src={burgerIcon} alt="icon" />
				</Link> */}
				<MenuButton
					open={menuOpen}
					onClick={() => setMenuOpen(!menuOpen)}
					color="white"
				/>
			</div>
			<Menu open={menuOpen}>{menuItems}</Menu>
		</nav>
	);
};

export default NavBar;
