import React from "react"; // , { useContext }
// import { useSpring, animated, useTransition } from "react-spring";
import { Switch, Route, useLocation } from "react-router-dom";

import ProductsPage from "./components/ProductsPage/ProductsPage";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import ServicesPage from "./components/ServicesPage/ServicesPage";
import Footer from "./components/Footer/Footer";
import LandingPage from "./components/LandingPage/LandingPage";
import CompanyPage from "./components/CompanyPage/CompanyPage";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import NavBar from "./components/NavBar/NavBar";

import "./App.css";

const App = () => {
	const location = useLocation();
	return (
		<>
			<NavBar location={location} />
			<Switch>
				<Route exact path="/" component={LandingPage} />
				<Route exact path="/products/:id" component={ProductDetails} />
				<Route exact path="/products" component={ProductsPage} />
				<Route exact path="/services" component={ServicesPage} />
				<Route exact path="/company" component={CompanyPage} />
				<Route exact path="/contact-us" component={ContactUsPage} />
			</Switch>
			<Footer />
		</>
	);
};

export default App;
