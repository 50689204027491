import React, { useState, useCallback, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import shellIcon from "../../assets/Image66/Image66.png";
import agripLogo from "../../assets/agiplogo@2x.png";
import LNGlimited from "../../assets/Nigeria-LNG.png";
import totaltNigeriaIcon from "../../assets/TotalNigeriaLogo.svg";
import AccessBankPLCLogo from "../../assets/AccessBankPLCLogo.svg";
import azuralogo from "../../assets/azuralogo.png";
import unicef from "../../assets/unicef.png";
import mobilvectorlogo from "../../assets/mobil-vector-logo.svg";
import nigerdeltalogo from "../../assets/nigerdeltalogo.png";
import fhilogo from "../../assets/fhilogo.png";
import slumbergerlogo from "../../assets/slumbergerlogo.png";
import ahnilogo from "../../assets/ahnilogo.png";
import kenpoly from "../../assets/Image91.png";
import FidelityLogo from "../../assets/FidelityLogo.svg";
import Rightarrow from "../../assets/Rightarrow.svg";
// import "./styles.css";

const pages = [
	({ style }) => (
		<animated.div style={{ ...style }} className="project-list-container">
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Shell Petroleum Development Company
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={shellIcon} alt="shell icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Corporate maintenance of Caterpillar engines and provision of
						generator maintenance support services for ‘Swamp 1 East and Land 1
						East
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Nigeria LNG Limited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={LNGlimited} alt="Nigeria LNG Limited icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Maintenance & Repair of diesel drivers for emergency diesel
						generators, firewater pumps, mobile air compressors & their
						associated control system
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Nigerian Agip Oil Company Limited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={agripLogo} alt="agrip Oil Company Limited icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Generator maintenance in Lagos office and residential area,
						maintenance and supply of new gensets for Abuja office (HQ) and
						residential area
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Total E&P Nigeria Limited.
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={totaltNigeriaIcon} alt="Total Inc. icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						A major overhaul of Perkins 3012 emergency diesel generator at Obite
						gas plant. Supplied a rental 250kVA Perkins soundproof generator.
					</div>
				</div>
			</div>
		</animated.div>
	),
	({ style }) => (
		<animated.div style={{ ...style }} className="project-list-container">
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Access Bank Nigeria PLC
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={AccessBankPLCLogo} alt="shell icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Nationwide servicing and maintenance of generators.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Azura Power West Africa Limited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={azuralogo} alt="total nigeria icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Supply and installation of 12 units of transformers and 20
						streetlight units.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							United Nations Children’s Fund (UNICEF)
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={unicef} alt="agrip Oil Company Limited icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Generator maintenance in Lagos office and residential area,
						maintenance and supply of new gensets for Abuja office (HQ) and
						residential area.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Schlumberger Nigeria Limited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={slumbergerlogo} alt="Total Inc. icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Supply of different capacities of generators.
					</div>
				</div>
			</div>
		</animated.div>
	),
	({ style }) => (
		<animated.div style={{ ...style }} className="project-list-container">
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Achieving Health Nigeria Initiative (AHNi)
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={ahnilogo} alt="shell icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Generator service maintenance for AHNI SIDHAS state offices and GF
						surge offices.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Mobil Producing Nigeria Unlimited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={mobilvectorlogo} alt="total nigeria icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Provision of generators at all MPN field and offshore locations.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Family Health International (fhi360)
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={fhilogo} alt="agrip Oil Company Limited icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Maintenance of generators in different state offices.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Fidelity Bank
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={FidelityLogo} alt="Total Inc. icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Repairs and maintenance of generators nationwide.
					</div>
				</div>
			</div>
		</animated.div>
	),
	({ style }) => (
		<animated.div style={{ ...style }} className="project-list-container">
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Niger Delta Petroleum Resources Limited
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={nigerdeltalogo} alt="shell icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Supply of Caterpillar spares.
					</div>
				</div>
			</div>
			<div className="project-list-item">
				<div className="project-list-item-top">
					<div className="project-list-item-top-text">
						<div className="project-list-item-top-text-header">CLIENT</div>
						<div className="project-list-item-top-text-company">
							Kenule Beeson Saro-Wiwa Polytechnic, Rivers State
						</div>
					</div>
					<div className="project-list-item-top-image-wrapper">
						<img src={kenpoly} alt="total nigeria icon" />
					</div>
				</div>
				<div className="project-list-item-bottom">
					<div className="project-list-item-bottom-header">Project Summary</div>
					<div className="project-list-item-bottom-list">
						Supply of different capacities of generators.
					</div>
				</div>
			</div>
		</animated.div>
	),
];

export default function ProjectSlider() {
	useEffect(() => {
		const interval = setInterval(() => {
			onClick();
		}, 10000);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, []);
	const [index, set] = useState(0);
	const onClick = useCallback(() => set((state) => (state + 1) % 4), []);
	const transitions = useTransition(index, (p) => p, {
		from: {
			opacity: 0,
			transform: "translate3d(100%,0,0)",
			position: "static",
		},
		enter: { opacity: 1, transform: "translate3d(0%,0,0)", position: "static" },
		leave: {
			opacity: 0,
			transform: "translate3d(-50%,0,0)",
			position: "absolute",
		},
	});
	return (
		<div className="slider-wrapper">
			<div className="left-arrow" onClick={onClick}>
				<img src={Rightarrow} alt="left" />
			</div>
			<div className="simple-trans-main" onTouchMove={onClick}>
				{transitions.map(({ item, props, key }) => {
					const Page = pages[item];
					return <Page key={key} style={props} />;
				})}
			</div>
			<div className="right-arrow" onClick={onClick}>
				<img src={Rightarrow} alt="right" />
			</div>
		</div>
	);
}
