import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Select from "react-select";

// import helloimage from "../../assets/Consultancy.svg";
import sendMessage from "../../assets/send-message.svg";
import { cities } from "./data";
import DistanceIcon from "./DistanceIcon";
import TelephoneIcon from "./TelephoneIcon";
import locationImage from "../../assets/DSC04823-2.png";
import contactUsImage from "../../assets/contactUsImage.png";

import "./ContactUsPage.css";

function Mailto({ email, subject, body, ...props }) {
	return (
		<a
			href={`mailto:${email}?subject=${
				encodeURIComponent(subject) || ""
			}&body=${encodeURIComponent(body) || ""}`}
		>
			{props.children}
		</a>
	);
}

const ContactUsPage = () => {
	const fade = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	});
	// const [pagination, setPagination] = useState({});
	// const [selectedOption, setSelectedOption] = useState("")
	const [firstName, setFirstName] = useState("");
	const [lastName, SetLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [location, setLocation] = useState("");
	const [inquiry, setInquiry] = useState("");
	const [message, setMessage] = useState("");

	const inquiryOptions = [
		{ value: "Maintenance", label: "Maintenance" },
		{ value: "Rental", label: "Rental" },
		{ value: "Purchase", label: "Purchase" },
		{ value: "Job", label: "Job" },
		{
			value: "Installation/Commissioning",
			label: "Installation / Commissioning",
		},
		{ value: "Training/Consultancy", label: "Training / Consultancy" },
		// { value: 'vanilla', label: 'Vanilla' },
	];

	const locationOptions = cities;

	const handleLocationOptionChange = (e) => {
		setLocation(e.value);
	};

	const handleFirstNameChange = (e) => {
		setFirstName(e.target.value);
	};

	const handleLastNameChange = (e) => {
		SetLastName(e.target.value);
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handlePhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value);
	};

	const handleInquiryOptionChange = (e) => {
		// console.log("e", e);
		setInquiry(e.value);
	};

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};

	let subject = `${inquiry} Inquiry`;

	let body;
	if (message === "") {
		body = `Hello sir/ma, my name is ${lastName} ${firstName}, with mobile number ${phoneNumber}, currently located in ${location}......`;
	} else {
		body = `Hello sir/ma, my name is ${lastName} ${firstName}, with mobile number ${phoneNumber}, currently located in ${location}. ${message}`;
	}

	const handleSubmit = () => {};
	return (
		<animated.section style={fade} className="services-section-container">
			<div className="services-section-content-wrapper">
				<article className="services-content-item contact">
					<div className="contact-us-head-office-left">
						<div className="contact-us-location-title">Locations</div>
						<div className="contact-us-location-left-body">
							<div className="contact-us-location-state">
								Port Harcourt (Head Office)
							</div>
							<div className="contact-us-location-content-wrapper">
								<div className="contact-us-location-content-image-wrapper">
									{/* <img src={DistanceIcon} alt="location icon" /> */}
									<DistanceIcon />
								</div>
								<div className="contact-us-location-content">
									<div className="contact-us-location-content-address">
										Plot 477 Trans Amadi Ind. Layout (By Ordinance Junction), P.
										O. Box 3384 Trans Amadi, Port Harcourt, Nigeria.
									</div>
									<div className="contact-us-location-content-phone-wrapper">
										<div className="contact-us-location-phone-image">
											{/* <img src={TelephoneIcon} alt="telephone" /> */}
											<TelephoneIcon />
										</div>
										<div className="contact-us-location-phone-numbers">
											<p className="location-phone-number">
												<a href="tel:+234 803 314 5174"> +234 803 314 5174</a>
											</p>
											<p className="location-phone-number">
												<a href="tel:+2348140037988"> +234 814 003 7988</a>
											</p>
											<p className="location-phone-number">
												<a href="tel:+2348133570528"> +234 813 357 0528</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-us-head-office-right">
						<div className="contact-us-head-office-right-image">
							<img src={locationImage} alt="A view of port harcourt" />
						</div>
						<div className="contact-us-head-office-right-background"></div>
					</div>
				</article>
				<article className="services-content-item contact-sub">
					<div className="contact-us-location-left-body sub">
						<div className="contact-us-location-state">Abuja</div>
						<div className="contact-us-location-content-wrapper">
							<div className="contact-us-location-content-image-wrapper">
								{/* <img src={DistanceIcon} alt="location icon" /> */}
								<DistanceIcon />
							</div>
							<div className="contact-us-location-content">
								<div className="contact-us-location-content-address">
									Plot 178 Aminu Kano Crescent By Austoma Filling Station, Wuse
									2, Abuja, Nigeria.
								</div>
								<div className="contact-us-location-content-phone-wrapper">
									<div className="contact-us-location-phone-image">
										{/* <img src={TelephoneIcon} alt="telephone" /> */}
										<TelephoneIcon />
									</div>
									<div className="contact-us-location-phone-numbers">
										<p className="location-phone-number">
											<a href="tel:+2348129915440"> +234 812 991 5440</a>
										</p>
										<p className="location-phone-number">
											<a href="tel:+2348132033268"> +234 813 203 3268</a>
										</p>
										{/* <p className="location-phone-number">+234 813 357 0528</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-us-location-left-body sub-right">
						<div className="contact-us-location-state sub-right">Awka</div>
						<div className="contact-us-location-content-wrapper sub-right">
							<div className="contact-us-location-content-image-wrapper sub-right">
								{/* <img src={DistanceIcon} alt="location icon" /> */}
								<DistanceIcon />
							</div>
							<div className="contact-us-location-content sub-right">
								<div className="contact-us-location-content-address">
									Km 45 Enugu-Onitsha Expressway (Opposite Revenue House), Agu
									Awka, Anambra State, Nigeria.
								</div>
								<div className="contact-us-location-content-phone-wrapper">
									<div className="contact-us-location-phone-image">
										{/* <img src={TelephoneIcon} alt="telephone" /> */}
										<TelephoneIcon />
									</div>
									<div className="contact-us-location-phone-numbers">
										<p className="location-phone-number">
											<a href="tel:+2348129915448"> +234 812 991 5448</a>
										</p>
										<p className="location-phone-number">
											<a href="tel:+2348133585022"> +234 813 358 5022</a>
										</p>
										{/* <p className="location-phone-number">+234 813 357 0528</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</article>
				<article className="services-content-item contact-sub">
					<div className="contact-us-location-left-body sub">
						<div className="contact-us-location-state">Benin</div>
						<div className="contact-us-location-content-wrapper">
							<div className="contact-us-location-content-image-wrapper">
								{/* <img src={DistanceIcon} alt="location icon" /> */}
								<DistanceIcon />
							</div>
							<div className="contact-us-location-content">
								<div className="contact-us-location-content-address">
									No.1 Osaze Ikponmwun Avenue, Benin City, Edo State, Nigeria.
								</div>
								<div className="contact-us-location-content-phone-wrapper">
									<div className="contact-us-location-phone-image">
										{/* <img src={TelephoneIcon} alt="telephone" /> */}
										<TelephoneIcon />
									</div>
									<div className="contact-us-location-phone-numbers">
										<p className="location-phone-number">
											<a href="tel:+2348132033267"> +234 813 203 3267</a>
										</p>
										<p className="location-phone-number">
											<a href="tel:+2348033420468">+234 803 342 0468</a>
										</p>
										{/* <p className="location-phone-number">+234 813 357 0528</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-us-location-left-body sub-right">
						<div className="contact-us-location-state sub-right">Lagos</div>
						<div className="contact-us-location-content-wrapper sub-right">
							<div className="contact-us-location-content-image-wrapper sub-right">
								{/* <img src={DistanceIcon} alt="location icon" /> */}
								<DistanceIcon />
							</div>
							<div className="contact-us-location-content sub-right">
								<div className="contact-us-location-content-address">
									Plot 1, Block K Isolo Industrial Estate, Oshodi-Apapa
									Expressway (By Sadiku Bus-stop Beside Eco Bank), Ilasamaja,
									Lagos State, Nigeria.
								</div>
								<div className="contact-us-location-content-phone-wrapper">
									<div className="contact-us-location-phone-image">
										{/* <img src={TelephoneIcon} alt="telephone" /> */}
										<TelephoneIcon />
									</div>
									<div className="contact-us-location-phone-numbers">
										<p className="location-phone-number">
											<a href="tel:+2348129915454">+234 812 991 5454</a>
										</p>
										<p className="location-phone-number">
											<a href="tel:+2348140037993">+234 814 003 7993</a>
										</p>
										{/* <p className="location-phone-number">+234 813 357 0528</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</article>
				<article className="services-content-item contact">
					<div className="contact-section-left">
						<div className="contact-section-left-header">Get in Touch</div>
						<form onSubmit={handleSubmit} className="contact-section-left-form">
							<div className="contact-section-left-coloured">
								<div className="contact-section-left-double-input-item">
									<input
										type="text"
										value={firstName}
										onChange={handleFirstNameChange}
										placeholder="Your first name"
									/>
									<input
										type="text"
										value={lastName}
										onChange={handleLastNameChange}
										placeholder="Your last name"
									/>
								</div>

								<input
									className="contact-section-left-single-input-item"
									type="email"
									value={email}
									onChange={handleEmailChange}
									placeholder="Email"
								/>
								<div className="contact-section-left-double-input-item">
									<input
										type="text"
										value={phoneNumber}
										onChange={handlePhoneNumberChange}
										placeholder="Phone Number"
									/>
									<Select
										placeholder="location"
										onInputChange={handleLocationOptionChange}
										classNamePrefix="react-select"
										id="location-select"
										value={location}
										onChange={handleLocationOptionChange}
										options={locationOptions}
									/>
								</div>
							</div>

							{/* <input type="text" value={this.state.value} onChange={this.handleChange} /> */}
							<label className="bottom-label">Purpose of Inquiry</label>
							<Select
								className="contact-section-left-single-input-item select"
								classNamePrefix="react-select"
								value={inquiry}
								onChange={handleInquiryOptionChange}
								options={inquiryOptions}
								onInputChange={handleInquiryOptionChange}
							/>
							<label className="bottom-label">Message</label>
							<textarea
								className="contact-section-left-single-input-item message"
								type="text"
								value={message}
								onChange={handleMessageChange}
							/>
							{/* <input type="submit" value="Submit" /> */}

							<div className="mailto-button">
								<Mailto email={email} subject={subject} body={body}>
									Mail Us!
								</Mailto>
							</div>
						</form>
					</div>
					<div className="contact-section-right">
						<Mailto email={email} subject={subject} body={body}>
							<div className="contact-section-right-mailto-content">
								<div className="contact-section-right-mailto-content-image">
									<img src={sendMessage} alt="" />
								</div>
								<div className="contact-section-right-mailto-content-email">
									enquiries@jocaro.com
								</div>
							</div>
						</Mailto>
						<div className="contact-section-right-image">
							<div className="contact-section-right-image-background"></div>
							<div className="contact-section-right-image-wrapper">
								<img src={contactUsImage} alt="hello" />
							</div>
						</div>
					</div>
				</article>
			</div>
		</animated.section>
	);
};

export default ContactUsPage;
