// import perkins from "../assets/images/perkins.png";
// import cummings from "../assets/images/cummings.png";
// import betaPower from "../assets/images/betaPower.png";

// import CummingsEngine from "../../assets/CummingsEngine/CummingsEngine.png";
import Transformer from "../../assets/Group9237.png";
import dieselEngine from "../../assets/Group8929.png";
import PerkinsGenerator from "../../assets/Group8786.png";
import SKUKubotaGenerator from "../../assets/Group9302.png";

import CATGenerator1 from "../../assets/Group9235.png";

import johndeere from "../../assets/Group9236.png";

import MobileLightingTower from "../../assets/Group9259.png";

import Simax from "../../assets/Group9261.png";

import atlascopcoaircompressors from "../../assets/Group9263.png";

import IRCompressor from "../../assets/Group9260.png";

import KPLBLoadBank from "../../assets/Group9262.png";

import ASCOLoadBank from "../../assets/Group9336.png";
// import transformerInstallation from "../assets/images/transformerInstallation.png";
// import LightTowerRentals from "../assets/images/LightTowerRentals.png";
// import suitableDiesel from "../assets/images/suitableDiesel.png";
// import solarsolutions from "../assets/images/solarsolutions.png";
// import soundProof from "../assets/images/soundProof.png";
// import rental from "../assets/images/rental.png";
import locationData from "./nigeria_cities.json";

export const cities = locationData.map((data, index) => {
	return { label: data.city, value: data.city, id: index + 1 };
});

export const careerData = [
	{
		title: "Senior Consultant - Renewable Energy (Port Harcourt)",
		content:
			"From ₦ 350,000 per month + Bonus We are a strong and stable engineering company with effective presence in Nigeria and indeed other African Countries with the aim of providing quality engineering products.",
	},
	{
		title: "Senior Consultant - Renewable Energy (Port Harcourt)",
		content:
			"From ₦ 350,000 per month + Bonus We are a strong and stable engineering company with effective presence in Nigeria and indeed other African Countries with the aim of providing quality engineering products.",
	},
	{
		title: "Senior Consultant - Renewable Energy (Port Harcourt)",
		content:
			"From ₦ 350,000 per month + Bonus We are a strong and stable engineering company with effective presence in Nigeria and indeed other African Countries with the aim of providing quality engineering products.",
	},
];

export const contactData = [
	{
		branchTitle: "Port Harcourt (Main Office)",
		address: {
			location:
				"Plot 477 Trans Amadi Ind. Layout (By Ordinance Junction), P. O. Box 3384 Trans Amadi",
			state: "Port Harcourt",
			country: "Nigeria",
		},
		phones: ["08033145174", "08140037988", "08133570528"],
	},
	{
		branchTitle: "Abuja",
		address: {
			location:
				"Plot 178 Aminu Kano Crescent By Austoma Filling Station, Wuse 2",
			state: "Abuja",
			country: "Nigeria",
		},
		phones: ["08129915440", "08132033268"],
	},
	{
		branchTitle: "Benin",
		address: {
			location: "No.1 Osaze Ikponmwun Avenue, Benin City",
			state: "Edo State",
			country: "Nigeria",
		},
		phones: ["08132033267", "08033420468"],
	},
	{
		branchTitle: "Awka",
		address: {
			location:
				"Km 45 Enugu-Onitsha Expressway (Opposite Revenue House), Agu Awka ",
			state: "Anambra State",
			country: "Nigeria",
		},
		phones: ["08129915448", "08133585022"],
	},
	{
		branchTitle: "Lagos",
		address: {
			location:
				"Plot 1, Block K Isolo Industrial Estate, Oshodi-Apapa Expressway (By Sadiku Bus-stop Beside Eco Bank)",
			state: "Lagos State",
			country: "Nigeria",
		},
		phones: ["08129915454", "08140037993"],
	},
];

export const generators = [
	{
		id: "Perkins-Diesel-Engine-Soundproof-Generator",
		name: "Perkins Diesel Engine Soundproof Generator",
		cost: "₦ 4,000,000 - ₦ 10,800,000",
		spec: [
			{
				specName: "20 KVA",
				cost: "₦ 4,000,000 - ₦ 4,300,000",
			},
			{
				specName: "30 KVA",
				cost: "₦ 4,500,000 - ₦ 4,700,000",
			},
			{
				specName: "45 KVA",
				cost: "₦ 5,100,000 - ₦  5,300,000",
			},
			{
				specName: "100 KVA",
				cost: "₦ 7,700,000 - ₦ 7,900,000",
			},
			{
				specName: "150 KVA",
				cost: "₦ 10,600,000 - ₦ 10,800,000",
			},
		],
		img: PerkinsGenerator,
		summary:
			"Perkins diesel engine generator units are equipped with Leroy Somer main alternator for energy output with rapid-starting technology. In all conditions, the engines ensure outstanding performance while keeping operating cost minimal.",
		descriptionLine1: `Perkins diesel engine generators are economical and offer durable operation at prime and standby duties. The units are connected to an Alternator for an automatic power transfer ensuring swift power changes that suit the user’s needs
    Perkins generators can be used for a range of applications such as household, office and other industrial uses. The complete generating set is mounted in a weatherproof and acoustic enclosure designed to reduce the noise level to within EU limits. `,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "Cummins-40kva-1000kva-Diesel-Engine-Generators",
		name: "Cummins 40kva-1000kva Diesel Engine Generators",
		cost: "Request Price",
		img: dieselEngine,
		summary:
			"The Cummins diesel generators serve well in stationary, standby and prime power applications, with strong motor starting ability and a rapid recovery from momentary variation in current, voltage, or frequency- Temporary power changes.",
		descriptionLine1: `This range is equipped with PowerCommand digital controls featuring automatic remote start/stop, precision voltage and frequency regulation as well as AmpSentry protection for an integrated system combining engine and alternator controls, and precision engineering for optimal Genset performance.
    The Cummins 1000 kVA specifically features optional coolant heaters guaranteeing quick starting even in the most extreme operating conditions.
    `,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier? Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "Kubota-15kva-30kva-Diesel-Engine-Generators",
		name: "Kubota 15kva-30kva Diesel Engine Generators",
		cost: "Request Price",
		img: SKUKubotaGenerator,
		summary:
			"This range of diesel engine powered generators offers cleaner power as it utilizes engines incorporated with the TVCS combustion system which improves air/fuel mixture, resulting in cleaner emissions. Due to its unique alternator, it ensures the delivery of high-quality dependable power.",
		descriptionLine1: `It not only delivers quality power but quieter power. Sound levels at full load and no-load are reduced due to the exceptionalKubota Technology such as quiet cooling fans, second mufflers and noise absorbing ducts within the cabinets. The Kubota engines are protected against any abnormalities within the oil or cooling systems and all rotating parts are enclosed in a sturdy lockable enclosure, ensuring optimum safety. They also offer an easy maintenance protocol as oil, water and filters are located on one side. Centre lifting lugs and forklift slots make the transportation of the range as easy as possible.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "Caterpillar-500KVA-2000KVA-Diesel-Engine-Generators",
		name: "Caterpillar 500KVA-2000KVA Diesel Engine Generators",
		cost: "Request Price",
		img: CATGenerator1,
		summary:
			"Caterpillar generators have been revised to be one of the foremost manufacturers consistently delivering products known for their longevity, reliability and versatility; while remaining a cost-effective choice. With multiple options for expansion, customization and installation, this range of generators can meet a wide range of practical requirements",
		descriptionLine1: `The CAT generators run on low fuel consumption rates and provide exceptionally reliable power generation for prime, standby and continuous power needs. This range of diesel generators feature engines which utilize ACER TTM technology and offer electrical engine control. The generators are designed to meet ISO 8528-5 steady-state and transient response requirements and to accept 100%t rated load in one step.
    The top of this range is the unique 2000Kva diesel generator; which features industrial Silencer (10dBA),industry-leading motor-starting capabilities, leading mechanical/electrical design and high efficiency is recommended highly for office, mining sites, construction sites, data centres, hospitals etc.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "John-Deere-40KVA-250KVA-Diesel-Engine-Generators",
		name: "John Deere 40KVA- 250KVA Diesel Engine Generators",
		cost: "Request Price",
		img: johndeere,
		summary:
			"The John Deere range of generators operates on diesel power for highly reliable, synchronous electrical generation. Its compact brushless design features an automatic voltage regulation technology to make up for load and temperature variations.",
		descriptionLine1: `These diesel generators are easy to operate using the control systems that feature a digital push-start button. Navigation is made easy as it is available in multiple languages for automatic control. It can be installed as a parallel supplier for large power needs. These diesel generators are easy to operate using the control systems that feature a digital push-start button. Navigation is made easy as it is available in multiple languages for automatic control. It can be installed as a parallel supplier for large power needs. The mobile elements such as the distribution belt, fan and parts which during operation acquire high temperatures are accompanied by their corresponding protections, in compliance with the requirements of Machinery Safety Directive.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

export const transformers = [
	{
		id: "Beta-Power-Distribution-Transformer",
		name:
			"Beta Power Distribution Transformer) - 11Kv and 33Kv Rated Voltage and 50KVA - 500KVA Power rating",
		cost: "₦ 1,300,000  - ₦ 5,200,000",
		img: Transformer,
		spec: [
			{
				specName: "50KVA, 11KV",
				cost: "₦ 1,300,000 - ₦ 1,500,000",
			},
			{
				specName: "100KVA, 11KV",
				cost: "₦ 1,500,000 - ₦ 1,800,000",
			},
			{
				specName: "200KVA, 33KV",
				cost: "₦ 2,600,000 - ₦  2,900,000",
			},
			{
				specName: "300KVA, 33KV",
				cost: "₦ 3,100,000 - ₦ 3,400,000",
			},
			{
				specName: "500KVA, 33KV",
				cost: "₦ 4,900,000 - ₦ 5,200,000",
			},
		],
		summary:
			"All Beta distribution transformers come in three phase or single phase, high or low voltage, are oil immersed and loaded with ONAN, ONAF, OFWF, with Off-Load or On-Load Tap Changer, 100% Copper winding and used externally and internally.",
		descriptionLine1: `The 11kv and 33kv come as either Special Type transformers, hermetically Sealed transformers or conservator type transformers. For the special type and conservator type transformers, dimensions of transformer conservator are determined and designed after several heat calculations of the oil stored in expansion tank and oil level changes by heat.Being open to atmosphere, oil pressure changes continuously in Conservator Type Transformers due to thermal effects and exchange of air is dehumidified via air breather silica gel. The hermetically sealed transformers, being closed to the atmosphere, do not contain oxygen. Hence aging due to oxidation does not occur and transformer oil will not gather moisture. For this reason, Hermetically Sealed Transformer are more long lasting. The height is also low making it  installable in small areas and has the advantage of being used in compact transformer centers.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

export const lightingtowers = [
	{
		id: "8-10kVA-Arbor-Mobile-Lighting-Tower",
		name: "8 - 10kVA Arbor Mobile Lighting Tower",
		cost: "₦ 4,500,000  - ₦ 6,000,000",
		img: MobileLightingTower,
		summary:
			"The Arbor mobile lighting tower is a range that ensures safety and comfort. The rugged machine design and layout ensures durability as it is fitted with guided main coiled cables to avoid damage during tower operation. Safety is also guaranteed through its custom electrical protection from overload and circuit breaker. Mast rotatable by hand at 340o (340 degrees) It comes with a vertical mast of 8M maximum height, 4 floodlights of 1000W metal halide each and 8KVA integrated silent generating set",
		descriptionLine1: `The range promises ease of transportation due to its central lifting eye and sides forklift pockets that aids manual or hydraulic lifting method. This feature makes it best suited for public lighting, road work, events, construction and construction sites, oil and gas and industrial applications. The lighting towers boasts of the Kubota D1105 diesel engine with a synchronous alternator. It also features multi directionally adjustable and tiltable floodlights, a fuel tank capacity of 80litres and a fuel consumption rate of 1.85litres/hour.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

export const solar = [
	{
		id: "Simax-Mono-crystalline-Solar-Panel",
		name: "Simax Mono-crystalline Solar Panel",
		cost: "₦ 50,000 - ₦ 70,000",
		img: Simax,
		summary:
			"The 300w Simax Solar Panel’s glass is characterized by High transmittance and low reflection, High solar transmittance, mechanical strength and flatness. Its Strong compressive strength,Certified to withstand high winds of 2400Pa, comes highly recommended by international reputable labs.",
		descriptionLine1: `The solar panel has a High output-power and conversation efficiency of 18%-22%, high shunt-resistance and bypass diode minimizes the power drop by shade providing excellent low light effect and low breakage rate. The solar panels frame is characterized by an aluminium extrusion profile with prompt delivery, subtle edges and extrusion for construction and other industrial purposes. The Junction Box has  High current and voltage carrying capacity. Its design ensures Simple, quick and safe effective field assembly. The solar panel can be used in the outdoor iron environment and its packaged with its  Expansion connector available for future requirement.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

export const compressors = [
	{
		id: "Atlas-Copco-Compressor",
		name: "Atlas Copco Compressor",
		cost: "Request Price",
		img: atlascopcoaircompressors,
		summary:
			"The Atlas Copco’s famous compression element ensures the high performance and large air delivery. The components manufactured to the highest standards and precision aligned roller bearings ensure long service life with maximum reliability. Known for durability and reliability it offers optimum performance having gone through extensive testing on core components like the oil separator, oil filter, coolers and electric cubicle.",
		descriptionLine1: `Atlas compressors tackle extreme duties with ease. The atlas compressors are powered by heavy duty Cummings diesel engine for extended operation. Germany MANN oil filters ensure longer working life and saves the maintenance time and cost.
    The compressors sport thick soundproofing for super low noise levels in accordance with regulations
    Its exquisite  Cummings engine helps to improve the reliability and reduce the fuel consumption making the compressors suitable for mining, hydropower, oil and gas exploitation, borehole, shipyard, construction and chemical industry applications.`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "Ingersoll-Rand-Compressor",
		name:
			"Ingersoll Rand Compressor with Actual Free Air Delivery from 375cfm - 1000cfm",
		cost: "Request Price",
		img: IRCompressor,
		summary:
			" Made with superior components the portable air compressors ensures productivity and durability with a unique cool-box design that prolongs component life. Galvanneal sheet metal on the front panel and a new paint process improve corrosion resistance — maintaining the compressor’s appearance as well as its resale value. Its outstanding pressure ratings provide the support you need for a variety of applications.",
		descriptionLine1: `The compressors  provide a wide range of safety options which include; 
    electric brakes with towing lights, side markers, and reflectors for additional safety during transportation
    External, single-point lifting bail for easy hoisting
    Heavy-duty “A” frame drawbar with adjustable-height pintle eye and safety chains for easy hook-up to vehicle and stability while towing
    Full-length lockable side doors with pneumatic lift springs for accessibility and serviceability
    Automatic shutdown protection with indicator lights during
    – High discharge air temperature
    – Low engine oil pressure
    – High engine coolant temperature
    – Low fuel level`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

export const loadBanks = [
	{
		id: "Keypower-Load-Bank",
		name: "Keypower Load Bank (300KVA - 2000KVA power rating)",
		cost: "Request Price",
		img: KPLBLoadBank,
		summary:
			"The Keypower Load bank boasts of premium components made from stainless steel sheathed elements manufactured from high grade magnesium oxide. Its switches, knobs and indicators are made by Siemens,all  also fitted with OMRON intermediate relays and PHOENIX terminal blocks to ensure durability, reliability.",
		descriptionLine1: `This range operates Forced air-cooled cooling system. Its spectacular overheating protection consists of cooling fans, failure protection and overload protection with alarm guaranteeing longer service life. The Keypower Load Bank offers ease of use and comfort as its control mode could be local manual control or manual control via a remote control panel`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
	{
		id: "Asco-Froment-Load-Bank",
		name: "Asco-Froment Load Bank from 300KVA - 2000KVA power rating",
		cost: "Request Price",
		img: ASCOLoadBank,
		summary:
			"Asco- Froment Load Banks are built with a heavy duty, welded monocoque  construction and equipped with SIGMA which  provides best-in-class control with high level instrumentation, load control and data acquisition. The ranges’ sturdy construction provides unrivalled reliability in tough climatic conditions.",
		descriptionLine1: `The Asco Load Banks sport separate enclosures for control, switchgear and power connections and a horizontal hot air discharge system to prevent damage by operational temperature  changes and the entry of direct sunlight and moisture. Its conservative rated non-finned stainless steel elements cautiously incorporated to avoid potential risks ensures long operating life.
    This range of load banks can be used for various applications which include but are not limited to: equipment rental supply, data center back-up power and UPS testing,OEM generator testing, critical facility back-up power testing, back up power servicing and commissioning`,
		descriptionLine2: `Here at Jocaro, we’re sure to have something that will meet your power generation needs. We also stock parts of this range should you already have a generator with a faulty part. Contact us today to see how we can help make your decision that little bit easier. Our experts are available to provide any additional information you might require.`,
	},
];

// export const topServices = [
//   {
//     name: "Generator Maintenance",
//     cost: "₦ 100,000 - ₦ 500,000",
//     img: generatorMaintenance
//   },
//   {
//     name: "Transformer Installation",
//     cost: "₦ 100,000 - ₦ 500,000",
//     img: transformerInstallation
//   },
//   {
//     name: "Lighting Tower Rentals",
//     cost: "₦ 100,000 - ₦ 500,000",
//     img: LightTowerRentals
//   }
// ];

// export const blogPost = [
//   {
//     name: "The 20 kVA Perkins Sound-proof Diesel Generator Engine",
//     img: soundProof
//   },
//   {
//     name: "Hybrid Solar Solutions, A Beginner’s Guide",
//     img: solarsolutions
//   },
//   {
//     name: "How to Pick a Suitable Diesel Engine to Avoid Waste",
//     img: suitableDiesel
//   }
// ];

export const porductItems = [
	{
		title: "Generators",
	},
	{
		title: "air conditioners",
	},
	{
		title: "pressure washers",
	},
	{
		title: "work platforms",
	},
	{
		title: "pump",
	},
	{
		title: "lightning towers",
	},
];

export const allProducts = [].concat(
	generators,
	transformers,
	lightingtowers,
	solar,
	compressors,
	loadBanks
);
